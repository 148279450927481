<template>
  <div class="register border-radius">
    <div class="loading" v-show="isLoading">
      <a-spin>
        <a-icon
          slot="indicator"
          type="loading"
          style="font-size: 24px; color: #f39800"
          spin
        />
      </a-spin>
    </div>
    <div class="top">
      <img src="@/assets/banner-1.png" />
    </div>
    <div class="register-form">
      <div class="register-box">
        <a-form-model
          layout="vertical"
          ref="reportForm"
          :model="form"
          :rules="rules"
        >
          <a-form-model-item
            ref="enterpriseName"
            class="form-item"
            prop="enterpriseName"
          >
            <label class="iconfont icon-hps-apptext"
              ><span>企业名称</span></label
            >
            <a-textarea
              v-model="form.enterpriseName"
              placeholder="请输入企业名称"
              auto-size
              :maxLength="50"
              @blur="
                () => {
                  $refs.enterpriseName.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <!-- <a-form-model-item
            ref="enterpriseCode"
            class="form-item"
            prop="enterpriseCode"
          >
            <label class="iconfont icon-hps-appwotijiaode"
              ><span>企业工商代码</span></label
            >
            <a-textarea
              v-model="form.enterpriseCode"
              placeholder="请输入企业工商代码"
              auto-size
              :maxLength="50"
              @blur="
                () => {
                  $refs.enterpriseCode.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="enterpriseCorporateName"
            class="form-item"
            prop="enterpriseCorporateName"
          >
            <label class="iconfont icon-hps-apptab_icon_my_nor1"
              ><span>企业法人</span></label
            >
            <a-textarea
              v-model="form.enterpriseCorporateName"
              placeholder="请输入企业法人"
              auto-size
              :maxLength="50"
              @blur="
                () => {
                  $refs.enterpriseCorporateName.onFieldBlur();
                }
              "
            />
          </a-form-model-item> -->
          <!-- <a-form-model-item
            ref="scale"
            class="form-item form-item-flex pd border-bottom"
            prop="scale"
          >
            <label class="iconfont icon-hps-appwodebumen"
              ><span>企业人数</span></label
            >
            <div>
              <select
                class="form-select"
                v-model="form.scale"
                @change="
                  () => {
                    $refs.scale.onFieldBlur();
                  }
                "
              >
                <option disabled selected value class="grey">
                  请选择企业人数
                </option>
                <option v-for="(item, index) in scaleLists" :key="index">
                  {{ item }}
                </option>
              </select>
              <a-icon type="caret-down" />
            </div>
          </a-form-model-item>
          <a-form-model-item
            ref="enterpriseAddress"
            class="form-item mt10"
            prop="enterpriseAddress"
          >
            <label class="iconfont icon-hps-appquyu"
              ><span>企业地址</span></label
            >
            <a-textarea
              v-model="form.enterpriseAddress"
              placeholder="请输入企业地址"
              auto-size
              :maxLength="50"
              @blur="
                () => {
                  $refs.enterpriseAddress.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="enterpriseAddressDetail"
            class="form-item"
            prop="enterpriseAddressDetail"
          >
            <label class="iconfont icon-hps-appquyu"
              ><span>详细地址</span></label
            >
            <a-textarea
              v-model="form.enterpriseAddressDetail"
              placeholder="请输入详细地址"
              auto-size
              :maxLength="50"
              @blur="
                () => {
                  $refs.enterpriseAddressDetail.onFieldBlur();
                }
              "
            />
          </a-form-model-item> -->
          <a-form-model-item
            ref="contactName"
            class="form-item"
            prop="contactName"
          >
            <label class="iconfont icon-hps-apptab_icon_my_nor1"
              ><span>联系人</span></label
            >
            <a-textarea
              v-model="form.contactName"
              placeholder="请输入联系人"
              auto-size
              :maxLength="50"
              @blur="
                () => {
                  $refs.contactName.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="contactMobile"
            class="form-item"
            prop="contactMobile"
          >
            <label class="iconfont icon-hps-appshoujihaoma"
              ><span>联系电话</span></label
            >
            <a-input
              type="tel"
              v-model="form.contactMobile"
              placeholder="请输入联系电话"
              auto-size
              :maxLength="11"
              @blur="
                () => {
                  $refs.contactMobile.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div class="handle-btn">
      <a-button class="submit" @click="submit">提交</a-button>
    </div>
  </div>
</template>
<script>
import { addEnterprise } from "@/utils/api";
import { FormModel, Input, Button, Icon, Spin } from "ant-design-vue";
export default {
  components: {
    AFormModel: FormModel,
    AFormModelItem: FormModel.Item,
    AButton: Button,
    ASpin: Spin,
    AInput: Input,
    ATextarea: Input.TextArea,
    AIcon: Icon,
  },
  data() {
    const validatorTrim = (rule, value, callback) => {
      const reg = /^[0-9]+$/;
      if (!value) {
        callback(new Error("请输入联系电话"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    return {
      scaleLists: [
        "少于50人",
        "50-150人",
        "150人-500人",
        "500-1000人",
        "1000-5000人",
        "5000-10000人",
        "10000人以上",
      ],
      form: {
        enterpriseName: "",
        // enterpriseCode: "",
        // enterpriseCorporateName: "",
        // scale: "",
        // enterpriseAddress: "",
        // enterpriseAddressDetail: "",
        contactMobile: "",
        contactName: "",
      },
      isLoading: false,
      rules: {
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        enterpriseCode: [
          { required: true, message: "请输入企业工商代码", trigger: "blur" },
        ],
        enterpriseCorporateName: [
          { required: true, message: "请输入企业法人", trigger: "blur" },
        ],
        scale: [{ required: true, message: "请选择企业人数", trigger: "blur" }],
        enterpriseAddress: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
        ],
        enterpriseAddressDetail: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        contactMobile: [{ validator: validatorTrim, trigger: "blur" }],
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.addEnterprise();
        } else {
          console.log(valid);
        }
      });
    },
    async addEnterprise() {
      const { resp_msg, resp_code } = await addEnterprise(this.form);
      if (resp_code == 0) {
        // this.$message.success(resp_msg);
        this.form = {
          enterpriseName: "",
          contactMobile: "",
          contactName: "",
        };
        this.$router.push("/reg-result");
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.$message.error(resp_msg);
      }
    },
  },
};
</script>
<style scoped lang="less">
.register {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-bottom: 5vh;
}
.top {
  img {
    max-width: 75rem;
    width: 100%;
    height: auto;
  }
}
.register-form {
  padding: 0 1.6%;
  .iconfont {
    font-size: 1.6rem;
    span {
      font-size: 1.4rem;
      margin-left: 5px;
    }
  }
}
.register-box {
  background: rgba(255, 255, 255, 0.5);
  padding: 0 14px;
  position: relative;
  border-radius: 16px;
  padding-top: 15px;
}
.handle-btn {
  margin-top: 7vh;
  position: relative;
  width: 80%;
  .submit {
    border-radius: 16px;
  }
}
.form-item {
  //   margin-bottom: 1.8rem !important;
  .ant-input {
    margin-top: 1.5rem;
  }
}
.form-select {
  // width: 50%;
  text-align: right;
  border: 0;
  outline: 0;
  background: none;
}
select {
  direction: rtl; // 文本方向右到左
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-align: right;
  /*在选择框的最右侧中间显示小箭头图片*/
  /* background: url("http://ourjs.github.io/static/2015/arrow.png") no-repeat scroll right center transparent;*/
  background-color: transparent;

  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 10px;
  color: #909399;
}
select option {
  // direction: ltr; // 文本方向左到右
  text-align: right;
}
</style>